export default defineAppConfig({
  customSaas: false,
  sidebarWidth: 270,
  languageStrings: {
    company: 'Empresa',
    companies: 'Empresas',
    briefing: {
      visibility: 'Si la visibilidad es oculto o público se asigna internamente a Taohirata.'
    }
  }
})